import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { AppContext } from '../../context/context';

const Pager: React.FunctionComponent<any> = (props) => {
  const context = useContext(AppContext);
  if (!props.context) return <></>;
  if (props.context.numberOfPages === 1) return <></>;

  let localePath: string = '';
  if (context.locale === 'en_US') localePath = '/en';

  const page = [];
  for (let i: number = 0; i < props.context.numberOfPages; i++) {
    const activeClass: string = props.context.pageNumber === i ? 'active' : '';
    if (i === 0) {
      page.push(
        <Link
          className={`util__pager-list-item link__alpha ${activeClass}`}
          key={`pager-${props.basePath}-${i}`}
          to={`${localePath}${props.basePath}`}>
          {i + 1}
        </Link>
      );
    } else {
      page.push(
        <Link
          className={`util__pager-list-item link__alpha ${activeClass}`}
          key={`pager-${props.basePath}-${i}`}
          to={`${localePath}${props.path}${i + 1}`}>
          {i + 1}
        </Link>
      );
    }
  }

  return (
    <nav className="util__pager">
      <ul className="util__pager-list ff-mono">{page}</ul>
    </nav>
  );
};
export default Pager;
